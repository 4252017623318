import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, EcommerceGuard } from '@bitf/guards';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';

import { SignInComponent } from '@modules/login/sign-in/sign-in.component';
import { LogoutComponent } from '@modules/login/logout/logout.component';
import { RedirectComponent, GetAuthTokenComponent } from '@bitf/components/functional';
import { MainLayoutComponent } from './core/components/main-layout/main-layout.component';
import { TermsOfUseComponent } from './core/components/terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'afterauth',
    component: GetAuthTokenComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./libs/bitforce/modules/changelog/changelog.module').then(m => m.BitfChangelogModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('src/app/modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'projects',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('src/app/modules/projects/projects.module').then(m => m.ProjectsModule),
      },
      {
        path: 'robo-rxn',
        loadChildren: () => import('src/app/modules/robo-rxn/robo-rxn.module').then(m => m.RoboRxnModule),
      },
      {
        path: 'learn',
        loadChildren: () => import('src/app/modules/learn/learn.module').then(m => m.LearnModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'subscription',
        loadChildren: () =>
          import('src/app/modules/subscription/subscription.module').then(m => m.SubscriptionModule),
        canActivateChild: [AuthGuard, EcommerceGuard],
      },
      {
        // for backward compatibility
        path: 'user-subscription',
        redirectTo: 'account/subscription',
      },
      {
        path: 'account',
        loadChildren: () => import('src/app/modules/account/account.module').then(m => m.AccountModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'actions',
        loadChildren: () => import('src/app/modules/actions/actions.module').then(m => m.ActionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'streaming',
        loadChildren: () => import('src/app/modules/streaming/streaming.module').then(m => m.StreamingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'collaborators',
        loadChildren: () =>
          import('src/app/modules/collaborators/collaborators.module').then(m => m.CollaboratorsModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'public',
        loadChildren: () => import('src/app/modules/public/public.module').then(m => m.PublicModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'tmap',
        loadChildren: () => import('src/app/modules/tmap/tmap.module').then(m => m.TmapModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'model-tuner',
        loadChildren: () =>
          import('@app/modules/model-tuner/model-tuner.module').then(m => m.ModelTunerModule),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'optical-chemical-recognition',
        loadChildren: () =>
          import('@app/modules/optical-chemical-recognition/optical-chemical-recognition.module').then(
            m => m.OpticalChemicalRecognitionModule
          ),
        canActivateChild: [AuthGuard],
      },
      {
        path: 'terms-of-use',
        component: TermsOfUseComponent,
      },
      {
        path: 'redirect',
        component: RedirectComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
