<div class="plan-container"
  [ngClass]="{'plan-container--current-plan': isCurrentPlan, 'border-light': showBorder}">
  <div *ngIf="showHeader"
    class="mb-7">
    <h3 class="mb-3"
      [title]="plan.name">{{plan.name}}</h3>
    <p [title]="plan.levels[levelIndex]?.description"
      [innerHTML]="plan.levels[levelIndex]?.description"></p>
  </div>
  <div *ngIf="plan.levels[levelIndex]?.limits?.overageTrainingCostRates?.length">
    <p class="small--xs text-01 mb-1">Cost based on monthly compute usage:</p>
    <div class="d-flex align-items-center"
      *ngFor="let item of plan.levels[levelIndex]?.limits?.overageTrainingCostRates">
      <p class="mr-5 small--sm flex-basis-33">{{item.hours}}</p>
      <strong>{{item.cost}}</strong>
    </div>
  </div>

  <button *ngIf="buttonAction === 'upgrade'"
    ibmButton="primary"
    class="mt-7"
    [disabled]="isDisabled"
    (click)="onChangePlan()">Pay by credit card
    <ibm-icon-arrow-right size="16"
      class="bx--btn__icon"></ibm-icon-arrow-right></button>
</div>