import { Injectable } from '@angular/core';
// NOTE this is exposed here from '@bitf/services'
import { ConfigService, PaymentsService } from '.';

import { AuthService } from '@bitf/services/auth/auth.service';
import { GenericService } from '@bitf/services/generic/generic.service';
import { AttemptsService } from '@core/services/api/attempts.service';
import { CollaboratorsService } from '@core/services/api/collaborators.service';
import { MoleculesService } from '@core/services/api/molecules.service';
import { DraftsService } from '@core/services/api/drafts.service';
import { ProjectsService } from '@core/services/api/projects.service';
import { UsersService } from '@core/services/api/users.service';
import { EmailService } from '@core/services/api/email.service';
import { ReactionsService } from '@core/services/api/reactions.service';
import { ActionsService } from '@core/services/api/actions.service';
import { AdminService } from '@core/services/api/admin.service';
import { ReagentsService } from '@core/services/api/reagents.service';
import { ReactionsCollectionService } from '@core/services/api/reactions-collection.service';
import { ChallengesService } from '@core/services/api/challenges.service';
import { ChallengesResultsService } from '@core/services/api/challenges-results.service';
import { ParagraphToActionsService } from '@core/services/api/paragraph-to-actions.service';
import { AiModelsService } from '@core/services/api/ai-models.service';
import { RetrosynthesisService } from '@core/services/api/retrosynthesis.service';
import { RetrosynthesisSequencesService } from '@core/services/api/retrosynthesis-sequences.service';
import { GroupsService } from '@core/services/api/groups.service';
import { AiTrainingModelsService } from '@core/services/api/ai-training-models.service';
import { AiTrainingFilesService } from '@core/services/api/ai-training-files.service';
import { SynthesisExecutionsService } from '@core/services/api/synthesis-executions.service';
import { SynthesisProceduresService } from '@core/services/api/synthesis-procedures.service';
import { RobotRxnService } from '@core/services/api/roborxn.service';
import { FileEntriesService } from '@core/services/api/file-entries.service';
import { ActivitiesService } from '@core/services/api/activities.service';
import { ReactionPropertyPredictionService } from './reaction-property-prediction.service';
import { OpticalChemicalRecognitionService } from './optical-chemical-recognition.service';
import { HubspotService } from './hubspot.service';
import { PlansService } from './plans.service';
import { UserBillingInformationService } from './user-billing-informations.service';
import { AddressesService } from './addresses.service';
import { BillingsService } from './billings.service';
import { SubscriptionsService } from './subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public aiModels: AiModelsService,
    public attempts: AttemptsService,
    public collaborators: CollaboratorsService,
    public drafts: DraftsService,
    public projects: ProjectsService,
    public molecules: MoleculesService,
    public users: UsersService,
    public emails: EmailService,
    public reactions: ReactionsService,
    public actions: ActionsService,
    public admin: AdminService,
    public auth: AuthService,
    public reagents: ReagentsService,
    public generic: GenericService,
    public reactionsCollection: ReactionsCollectionService,
    public challenges: ChallengesService,
    public challengesResults: ChallengesResultsService,
    public paragraphToActions: ParagraphToActionsService,
    public synthesisProcedures: SynthesisProceduresService,
    public synthesisExecutions: SynthesisExecutionsService,
    public retrosynthesis: RetrosynthesisService,
    public retrosynthesisSequences: RetrosynthesisSequencesService,
    public groups: GroupsService,
    public aiTrainingModels: AiTrainingModelsService,
    public aiTrainingFiles: AiTrainingFilesService,
    public robotRxn: RobotRxnService,
    public configs: ConfigService,
    public fileEntries: FileEntriesService,
    public activities: ActivitiesService,
    public reactionPropertyPrediction: ReactionPropertyPredictionService,
    public opticalChemicalRecognition: OpticalChemicalRecognitionService,
    public hubspot: HubspotService,
    public plans: PlansService,
    public addresses: AddressesService,
    public userBillingInformation: UserBillingInformationService,
    public subscriptions: SubscriptionsService,
    public billings: BillingsService,
    public payments: PaymentsService
  ) {}
}
