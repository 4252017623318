import * as Models from '@models/index';

// NOTE every new model / entity needs to be registered here
export const modelsMapperStrategy = (item, entityName) => {
  switch (entityName) {
    case 'activities':
      return new Models.Activity(item);
    case 'countries':
      return new Models.Country(item);
    case 'attempts':
      return new Models.Reaction(item);
    case 'collaborators':
      return new Models.Collaborator(item);
    case 'drafts':
      return new Models.Reaction(item);
    case 'molecules':
      return new Models.Molecule(item);
    case 'projects':
      return new Models.Project(item);
    case 'reactions-collection':
      return new Models.Reaction(item);
    case 'reactions':
      return new Models.Reaction(item);
    case 'users':
      return new Models.User(item);
    case 'prediction-task':
      return new Models.PredictionTask(item);
    case 'predictions':
      return new Models.PredictionTask(item);
    case 'login':
      return new Models.Login(item);
    case 'reagents':
      return new Models.Reagent(item);
    case 'get-random-challenge':
    case 'challenges':
      return new Models.Challenge(item);
    case 'challenges-results':
      return new Models.ChallengeResult(item);
    case 'invitations':
      return new Models.Invitation(item);
    case 'stats':
    case 'challenges-results/stats':
      return new Models.ChallengesResultsStats(item);
    case 'retrosynthesis/config':
      return new Models.RetrosynthesisWizardConfig(item);
    case 'retrosynthesis':
    case 'create-from-sequence':
    case 'expand-molecule':
    case 'create-from-paragraph':
    case 'retrosynthesis-snapshots':
    case 'retrosynthesis-attempt':
    case 'retrosynthesis-with-procedures':
      return new Models.Retrosynthesis(item);
    case 'synthesis':
      return new Models.SynthesisProcedure(item);
    case 'synthesis-executions':
      return new Models.SynthesisExecution(item);
    case 'synthesis/latest':
      return new Models.LatestSynthesis(item);
    case 'retrosynthesis-collection':
    case 'sequences':
      return new Models.RetrosynthesisSequence(item);
    case 'sequence-molecule':
    case 'node':
      return new Models.RetrosynthesisMolecule(item);
    case 'queue-state':
      return new Models.QueueState(item);
    case 'config':
      return new Models.RoboRxnConfig(item);
    case 'tune-rxn':
      return new Models.TuneRxnConfig(item);
    case 'status':
      return new Models.RoboRxnSummary(item);
    case 'actions-schema':
      return new Models.ActionSchema(item);
    case 'user-groups':
      return new Models.Group(item);
    case 'ai-training-models':
      return new Models.AiTrainingModel(item);
    case 'ai-training-files':
      return new Models.AiTrainingFile(item);
    case 'similar-reactions':
      return new Models.SimilarReaction(item);
    case 'tmap-reaction':
      return new Models.TmapReaction(item);
    case 'tmap':
      return new Models.Tmap(item);
    case 'file-entries':
      return new Models.FileEntry(item);
    case 'ingredients':
      return new Models.ReactionSettingsIngredient(item);
    case 'reaction-settings':
      return new Models.ReactionSettings(item);
    case 'reaction-properties-predictions':
      return new Models.ReactionPropertyPrediction(item);
    case 'annotations':
      return new Models.SynthesisAnnotation(item);
    case 'reaction-completion-predictions':
      return new Models.ReactionCompletionPrediction(item);
    case 'reaction-completions':
      return new Models.ReactionCompletion(item);
    case 'optical-chemical-recognition':
      return new Models.OpticalChemicalRecognition(item);
    case 'plans':
      return new Models.Plan(item);
    case 'user-billing-informations':
      return new Models.UserBillingInformation(item);
    case 'user-plan-usage':
      return new Models.UserPlanUsage(item);
    case 'subscriptions':
      return new Models.Subscription(item);
    case 'subscription-cost':
      return new Models.SubscriptionCost(item);
    case 'billing-overview':
      return new Models.BillingOverview(item);
    case 'socket-notifications':
      return new Models.SocketNotification(item);
    case 'optical-chemical-recognition-reaction-tree':
    case 'paragraph-actions':
    case 'like':
    case 'feedback':
    case 'emulate-status':
    case 'chemicals':
    case 'ai-models':
    case 'ai-model-categories':
    case 'share':
    case 'file-entries':
    case 'ingredient-categories':
    case 'hardware-settings':
    case 'molecule-information':
    case 'user-plan-limits':
    case 'address-lookup':
    case 'address-format':
    case 'payment-method':
    case 'plan-forecast':
    case 'plan-level':
    case 'payments':
    case '':
      return item;
    default:
      throw new Error(`No model mapper found for entity: ${entityName}`);
  }
};
