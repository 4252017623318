<ibm-sidenav [expanded]="sidenavActive"
  [hidden]="!sidenavActive"
  #mainSidenav>
  <ng-container *ngIf="!appSessionService.isAccountArea">
    <!-- main application sidenav -->
    <ibm-sidenav-item [route]="['home']"
      ibmHeapTrack="SIDENAV: Home"
      title="Home"
      [active]="router.isActive(router.createUrlTree(['home']).toString(), true)">
      <svg ibmIconHome
        size="16"></svg>
      Home
    </ibm-sidenav-item>
    <ibm-sidenav-item [route]="['projects', 'list']"
      ibmHeapTrack="SIDENAV: Projects"
      title="Projects"
      [active]="router.isActive(router.createUrlTree(['projects', 'list']).toString(), true)">
      <svg ibmIconFolders
        size="16"></svg>
      Projects
    </ibm-sidenav-item>
    <ibm-sidenav-menu title="Favorite projects"
      [expanded]="isFavoriteProjectMenuExpanded"
      *ngIf="projects?.length > 0"
      #favoriteProjectsMenu>
      <svg icon
        ibmIconStar
        *ngIf="!isFetchingFavoritesProjects"
        size="16"></svg>
      <svg icon
        *ngIf="isFetchingFavoritesProjects"
        ibmIconFade
        size="16"></svg>
      <ibm-sidenav-item *ngFor="let project of projects"
        [title]="project.name"
        [route]="['projects', project.id, project.name, 'dashboard']"
        [active]="
        router.isActive(
          router
            .createUrlTree(['projects', project.id, project.name])
            .toString(),
          false
        )
      ">
        {{ project.name }}
      </ibm-sidenav-item>
    </ibm-sidenav-menu>
    <ibm-sidenav-item [route]="['collaborators', 'list']"
      ibmHeapTrack="SIDENAV: Collaborators"
      title="Collaborators"
      [active]="router.isActive(router.createUrlTree(['collaborators', 'list']).toString(), true)">
      <svg ibmIconCollaborate
        size="16"></svg>
      Collaborators
    </ibm-sidenav-item>

    <div class="sidenav-divider"></div>

    <ibm-sidenav-item (click)="onCreate('retrosynthesis')"
      ibmHeapTrack="SIDENAV: Predict retrosynthesis"
      title="Predict retrosynthesis">
      <svg ibmIconNetwork_2
        size="16"></svg>
      Predict retrosynthesis
    </ibm-sidenav-item>

    <ibm-sidenav-item (click)="onCreate('forward-reaction')"
      ibmHeapTrack="SIDENAV: Predict product"
      title="Predict product">
      <svg ibmIconNetwork_1
        size="16"></svg>
      Predict product
    </ibm-sidenav-item>

    <ibm-sidenav-item (click)="onCreate('reaction-completion')"
      ibmHeapTrack="SIDENAV: Predict reagents"
      title="Predict reagents">
      <svg ibmIconChemistry
        size="16"></svg>
      Predict reagents
    </ibm-sidenav-item>

    <ibm-sidenav-item (click)="onCreate('synthesis')"
      ibmHeapTrack="SIDENAV: Plan a synthesis"
      title="Plan a synthesis">
      <svg ibmIconNetwork_3
        size="16"></svg>
      Plan a synthesis
    </ibm-sidenav-item>

    <div class="sidenav-divider"></div>

    <ibm-sidenav-item [route]="['actions', 'reaction-properties-prediction']"
      ibmHeapTrack="SIDENAV: Atom mapping"
      title="Atom mapping"
      [active]="router.isActive(router.createUrlTree(['actions', 'reaction-properties-prediction']).toString(), true)">
      <svg ibmIconSankeyDiagram
        size="16"></svg>
      Atom mapping
    </ibm-sidenav-item>
    <ibm-sidenav-item [route]="['actions', 'text-to-procedure']"
      ibmHeapTrack="SIDENAV: Text to procedure"
      title="Text to procedure"
      [active]="router.isActive(router.createUrlTree(['actions', 'text-to-procedure']).toString(), true)">
      <svg ibmIconMigrate
        size="16"></svg>
      Text to procedure
    </ibm-sidenav-item>
    <ibm-sidenav-item [route]="['optical-chemical-recognition']"
      ibmHeapTrack="SIDENAV: Reaction digitization"
      title="Reaction digitization"
      [active]="router.isActive(router.createUrlTree(['optical-chemical-recognition']).toString(), false)">
      <svg ibmIconObjectStorage
        size="16"></svg>
      Reaction digitization
    </ibm-sidenav-item>

    <ibm-sidenav-item [route]="['model-tuner']"
      *ngIf="apiService.auth?.user?.canUseModelTuner"
      ibmHeapTrack="SIDENAV: Model Tuner"
      title="Model Tuner"
      [active]="router.isActive(router.createUrlTree(['model-tuner']).toString(), false)">
      <svg ibmIconMachineLearningModel
        size="16"></svg>
      Model Tuner
    </ibm-sidenav-item>

    <div class="sidenav-divider"></div>

    <ibm-sidenav-item (click)="onExternalLinkClick('https://accelerate-science.github.io/rxn/')"
      ibmHeapTrack="SIDENAV: Learn"
      title="Learn">
      <svg ibmIconEducation
        size="16"></svg>
      Learn
    </ibm-sidenav-item>
  </ng-container>

  <!-- user account sidenav -->
  <ng-container *ngIf="appSessionService.isAccountArea">
    <ibm-sidenav-item [route]="['/', 'account']"
      title="Account overview"
      [active]="router.isActive(router.createUrlTree(['account', 'details']).toString(), false)"
      [isSubMenu]="true">
      Account overview
    </ibm-sidenav-item>
    <ibm-sidenav-item *ngIf="environment.ecommerceEnabled && !apiService.auth?.user?.hasFreePlan"
      [route]="['/', 'account', 'billing']"
      title="Billing"
      [active]="router.isActive(router.createUrlTree(['account', 'billing']).toString(), false)"
      [isSubMenu]="true">
      Billing
    </ibm-sidenav-item>
    <ibm-sidenav-item [route]="['/', 'account', 'keys']"
      title="My keys"
      [active]="router.isActive(router.createUrlTree(['account', 'keys']).toString(), true)"
      [isSubMenu]="true">
      My keys
    </ibm-sidenav-item>
  </ng-container>
</ibm-sidenav>

<div class="sidenav-footer text-center py-4"
  [ngClass]="{'d-block': sidenavActive}">
  <a class="bx--link"
    onclick="cookieconsent.openPreferencesCenter();">Cookie</a>
  |
  <a ibmLink
    [routerLink]="['/terms-of-use']"
    target="_blank">Terms</a>
  |
  <a ibmLink
    href="https://www.ibm.com/privacy/us/en/?lnk=flg-priv-usen"
    target="_blank">Privacy</a>
  |
  <a ibmLink
    href="https://share.hsforms.com/1OGflIdkDSF2ZBoUbVQ93ggdammb"
    target="_blank">Support</a>
</div>