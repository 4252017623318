import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPlanLevel, Plan } from '@app/core/models';
import { DialogsService } from '@app/core/services';
import { TrainingCostDetailDialogComponent } from '../training-cost-detail-dialog/training-cost-detail-dialog.component';
import { TrainingHoursHelpDialogComponent } from '../training-hours-help-dialog/training-hours-help-dialog.component';

@Component({
  selector: 'ibm-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit {
  @Input() plan: Plan;
  @Input() levelIndex: number;
  @Input() isCurrentPlan: boolean;
  @Input() buttonAction: 'upgrade';
  @Input() showHeader = true;
  @Input() showButtonAction = true;
  @Input() showHeaderFeatures = true;
  @Input() showBorder = false;
  @Input() isDisabled = true;
  @Output() changePlan = new EventEmitter<string>();

  constructor(private dialogsService: DialogsService) {}

  ngOnInit(): void {}

  onOpenTrainingCostDetails(level: IPlanLevel) {
    this.dialogsService.dialog
      .open(TrainingCostDetailDialogComponent, {
        data: level?.limits?.overageTrainingCostRates,
      })
      .afterClosed()
      .subscribe();
  }

  onOpenTrainingHoursHelp() {
    this.dialogsService.dialog
      .open(TrainingHoursHelpDialogComponent, {})
      .afterClosed()
      .subscribe();
  }

  onChangePlan() {
    this.changePlan.emit(this.plan.planType);
  }
}
