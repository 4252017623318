import { SuperModel } from './super-model.model';

export class Plan extends SuperModel {
  name: string;
  planType: EPlanType;
  currencyCode: string;
  levels: IPlanLevel[];
  countryCode2: string;

  get nameWithCountry() {
    return `${this.countryCode2} ${this.name}`;
  }
  get canActivate() {
    return this.levels.some(x => x.canActivate);
  }
  constructor(plan = {}) {
    super(plan);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      currencyCode: this.currencyCode,
      countryCode2: this.countryCode2,
      planType: this.planType,
      levels: this.levels,
    };
  }
}

export interface IPlanLevel {
  id: string;
  name: string;
  description: string;
  features: IPlanFeature[];
  limits: IPlanLimits;
  price: string;
  canActivate: boolean;
  planType: EPlanType;
  countryCode2: string;
}

export interface IPlanFeature {
  name: string;
  included: boolean;
  readOnly: boolean;
}

export interface IPlanLimits {
  trainingHours: string;
  overageTrainingCost: string;
  overageTrainingCostRates: IPlanOverageTrainingCostRate[];
  reactionPredictions: string;
  tuningCap: string;
  modelsCaps: string;
}

export interface IPlanOverageTrainingCostRate {
  hours: string;
  cost: string;
}

export enum EPlanType {
  FREE = 'free',
  PAYGO = 'paygo',
}
